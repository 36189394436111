import { graphql } from 'gatsby'
import React from 'react'
import { HeadProps } from '~src/components/Head'
import { HeaderPlaceHolder } from '~src/components/HeaderPlaceHolder'
import { Footer } from '~src/components/Footer'
import { Main } from '~src/units/Main'
import { FeaturesArticle, FeaturesSection } from '~src/units/Article'

export interface PlannerProps extends HeadProps {}

const PlannerPage: React.FC<PlannerProps> = ({ data }) => (
  <Main>
    <HeaderPlaceHolder />
    <FeaturesSection>
      <FeaturesArticle
        dangerouslySetInnerHTML={{ __html: data.markdownRemark!.html }}
      />
    </FeaturesSection>
    <Footer />
  </Main>
)

export default PlannerPage

export const pageQuery = graphql`
  query PlannerZhMDQuery {
    markdownRemark(frontmatter: { slug: { eq: "Planner-Zh" } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
